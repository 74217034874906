/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const { toast } = require('react-toastify');

exports.shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  const prevLocation = prevRouterProps?.location || {};
  const location = routerProps?.location || {};

  // Overlay scroll logic
  const prevOverlay =
    (prevLocation && prevLocation.state && prevLocation.state.overlay) || {};
  const overlay = (location && location.state && location.state.overlay) || {};
  if (
    (overlay.visible && !prevOverlay.visible) ||
    (overlay.visible === false && prevOverlay.visible === true)
  ) {
    return false;
  }

  // FAQ logic
  if (
    prevLocation &&
    prevLocation.pathname &&
    location &&
    location.pathname &&
    prevLocation.pathname === '/frequently-asked-questions/' &&
    location.pathname === '/frequently-asked-questions/'
  ) {
    return false;
  }

  return true;
};

exports.onRouteUpdate = () => {
  // segment analytics
  window.analytics && window.analytics.page();

  // clear toasts
  toast.dismiss();
};
