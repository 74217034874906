module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Grid","short_name":"Grid","start_url":"/","background_color":"white","theme_color":"#B4FC00","display":"minimal-ui","icon":"src/assets/images/black_favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"MC4SrtQFUPkWxFsglchiMM8bSruqYhBc","devKey":"MC4SrtQFUPkWxFsglchiMM8bSruqYhBc"},
    },{
      plugin: require('../node_modules/gatsby-plugin-launchdarkly/gatsby-browser.js'),
      options: {"plugins":[],"clientSideID":"5a1e037222882f0d6d6a8287","options":{"bootstrap":"localstorage"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
